import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import Users from "../../components/pages/users";
import {
  setUsersPage,
  setPageSize,
  setSearchQuery,
  setIncludeDisabledAction,
  fetchUsersIfNeeded,
  importUsers
} from "../../store/users/actions";
import {AppState} from "../../store/rootReducer";

const mapStateToProps = (state: AppState) => ({
  ...state.users
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    loadPage: async (pageNumber: number) => {
      dispatch(setUsersPage(pageNumber));
      await dispatch(fetchUsersIfNeeded(pageNumber, undefined, undefined));
    },
    onPageSizeChange: async (pageSize: number) => {
      const newPage = 0;
      dispatch(setUsersPage(newPage));
      dispatch(setPageSize(pageSize));
      await dispatch(fetchUsersIfNeeded(newPage, pageSize, undefined));
    },
    onQueryChange: async (query: string) => {
      const newPage = 0;
      dispatch(setSearchQuery(query));
      dispatch(setUsersPage(newPage));
      await dispatch(fetchUsersIfNeeded(newPage, undefined, query));
    },
    onIncludeDisabledChange: async (includeDisabled: boolean) => {
      const newPage = 0;
      dispatch(setIncludeDisabledAction(includeDisabled));
      dispatch(setUsersPage(newPage));
      await dispatch(fetchUsersIfNeeded(newPage));
    },
    importUsers: async () => {
      try {
        await dispatch(importUsers());
        await dispatch(fetchUsersIfNeeded(0, undefined, undefined));
      } catch (e) {
        console.log(e);
      }
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);

import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

library.add(faSearch);

interface UsersSearchProps {
  includeDisabled: boolean;
  currentQuery: string | undefined;
  onQueryChange: (query: string) => void;
  onIncludeDisabledChange: (includeDisabled: boolean) => void;
  usersBeingImported: boolean;
}
interface UsersSearchState {
  query: string | undefined;
}
class UsersSearch extends React.Component<UsersSearchProps, UsersSearchState> {
  static propTypes = {
    currentQuery: PropTypes.string,
    onQueryChange: PropTypes.func.isRequired,
    onIncludeDisabledChange: PropTypes.func.isRequired,
    usersBeingImported: PropTypes.bool.isRequired
  };
  constructor(props: UsersSearchProps) {
    super(props);
    this.state = {
      query: props.currentQuery
    };
  }
  onQueryChange = (e: React.SyntheticEvent<{value: string}>) => {
    this.setState({query: e.currentTarget.value});
    this.updateQueryProps(e.currentTarget.value);
  };
  updateQueryProps = _.debounce((newQuery: string) => {
    this.props.onQueryChange(newQuery);
  }, 1000);
  onIncludeDisabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = e.target.checked;
    this.updateIncludeDisabledFlag(newVal);
  };
  updateIncludeDisabledFlag = _.debounce((newVal: boolean) => {
    this.props.onIncludeDisabledChange(newVal);
  }, 1000);
  render() {
    return (
      <form className="pt-3">
        <div className="row m-0">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={this.state.query}
                  onChange={this.onQueryChange}
                  disabled={this.props.usersBeingImported}
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon="search" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 d-flex align-items-center pl-4">
            <div className="form-group">
              <input
                type="checkbox"
                id="includeDisabledUsers"
                className="form-check-input"
                checked={this.props.includeDisabled}
                onChange={this.onIncludeDisabledChange}
                disabled={this.props.usersBeingImported}
              />
              <label className="form-check-label" htmlFor="includeDisabledUsers">
                Include disabled users
              </label>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default UsersSearch;

import React, {useState} from "react";
import {ScreenExtensionsProps} from "../../types";
import Switcher from "../../../configuration/widgets/Switcher";
import {ValueType} from "../../../../common/input/types";

export default function DropdownConfig(props: ScreenExtensionsProps) {
  const [state, setState] = useState({
    externalEntity: props.editConfig && !!props.activeConfigField["entityName"],
    cascadeDropdown: props.editConfig && !!props.activeConfigField["parentDropDownListField"]
  });
  const onChange = (name: string, value: ValueType) => {
    return props.changeConfigValue(name, value);
  };
  return (
    <div className="form-group">
      <div className="border border-gray p-2">
        <section className="pb-2">
          <div className="form-check-inline mr-4">
            <input
              type="radio"
              className="form-check-input"
              id="domains"
              checked={!state.externalEntity}
              onChange={() => {
                setState({externalEntity: false, cascadeDropdown: state.cascadeDropdown});
                onChange("entityName", "");
                onChange("entityValue", "");
                onChange("entityId", "");
                onChange("parentDropDownListField", "");
                onChange("filterField", "");
              }}
              name="customDropdownDataSource"
            />
            <label className="form-check-label" htmlFor="domains">
              Domains
            </label>
          </div>
          <div className="form-check-inline">
            <input
              type="radio"
              className="form-check-input"
              id="external_entity"
              checked={state.externalEntity}
              onChange={() => {
                setState({externalEntity: true, cascadeDropdown: state.cascadeDropdown});
                onChange("selectionSource", "");
              }}
              name="customDropdownDataSource"
            />
            <label className="form-check-label" htmlFor="external_entity">
              External entity
            </label>
          </div>
        </section>
        {state.externalEntity ? (
          <>
            <div className="form-group d-flex w-100">
              <label className="pl-0 col-md-4">Name</label>
              <input
                onChange={val => onChange("entityName", val.currentTarget.value)}
                className="form-control col-md-8"
                type="text"
                required
                value={props.activeConfigField["entityName"]}
              />
            </div>
            <div className="form-group d-flex w-100">
              <label className="pl-0 col-md-4">Value</label>
              <input
                onChange={val => onChange("entityValue", val.currentTarget.value)}
                className="form-control col-md-8"
                type="text"
                required
                value={props.activeConfigField["entityValue"]}
              />
            </div>
            <div className="form-group d-flex w-100">
              <label className="pl-0 col-md-4">Id</label>
              <input
                onChange={val => onChange("entityId", val.currentTarget.value)}
                className="form-control col-md-8"
                type="text"
                required
                value={props.activeConfigField["entityId"]}
              />
            </div>
            {props.activeConfigField.widget === "dropdown" && (
              <div className="form-group">
                <Switcher
                  onChange={val => {
                    if (state.cascadeDropdown) {
                      onChange("parentDropDownListField", "");
                      onChange("filterField", "");
                    }
                    setState({externalEntity: state.externalEntity, cascadeDropdown: !!val});
                  }}
                  name="cascadeDropdown"
                  title="Cascade dropdown"
                  disabled={false}
                  isValid={true}
                  type="SLIDER"
                  value={state.cascadeDropdown}
                />
              </div>
            )}
            {props.activeConfigField.widget === "dropdown" && state.cascadeDropdown && (
              <>
                <div className="form-group d-flex w-100">
                  <label className="pl-0 col-md-4">Parent dropdown list field</label>
                  <input
                    onChange={val => onChange("parentDropDownListField", val.currentTarget.value)}
                    className="form-control col-md-8"
                    type="text"
                    required
                    value={props.activeConfigField["parentDropDownListField"]}
                  />
                </div>
                <div className="form-group d-flex w-100">
                  <label className="pl-0 col-md-4">Filter field</label>
                  <input
                    onChange={val => onChange("filterField", val.currentTarget.value)}
                    className="form-control col-md-8"
                    type="text"
                    required
                    value={props.activeConfigField["filterField"]}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <div className="form-group d-flex  w-100">
            <label className="pl-0 col-md-4">Selection Source</label>
            <input
              onChange={val => onChange("selectionSource", val.currentTarget.value)}
              className="form-control col-md-8"
              type="text"
              required
              value={props.activeConfigField["selectionSource"]}
            />
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import "./pagination.scss";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

library.add(faChevronLeft);
library.add(faChevronRight);

interface PaginationProps {
  pageOptions: number[];
  idPart: string;
  total: number;
  currentPage: number;
  pageSize: number;
  pageSelect: (page: number, pageSize: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  usersBeingImported: boolean;
}
const dots = <span>...</span>;
const createPaginationItem = (
  pageNumber: number,
  disabled: boolean,
  icon: "chevron-left" | "chevron-right" | null,
  props: PaginationProps,
  iconAction?: string
) => {
  return (
    <li
      className={`page-item ${disabled || props.usersBeingImported ? "disabled" : ""} ${
        pageNumber === props.currentPage ? "active" : ""
      }`}
      onClick={() => {
        if (!disabled) props.pageSelect(pageNumber, props.pageSize);
      }}
      key={pageNumber + (_.isString(icon) ? icon : "")}
      aria-label={iconAction}
    >
      <a className="page-link" href="#">
        {_.isString(icon) && <FontAwesomeIcon icon={icon} />}
        {_.isNull(icon) && pageNumber + 1}
      </a>
    </li>
  );
};
const PaginationCA: React.SFC<PaginationProps> = (props: PaginationProps) => {
  const pagesCount = Math.ceil(props.total / props.pageSize);
  const lastPage = Math.max(pagesCount - 1, 0);
  const resultsFrom = props.currentPage * props.pageSize + 1;
  const maxResultsTo = (props.currentPage + 1) * props.pageSize;
  const resultsTo = maxResultsTo > props.total ? props.total : maxResultsTo;
  return (
    <div>
      <div className="row m-0 pt-3">
        <div className="col">
          <Dropdown>
            <Dropdown.Toggle id={"dropdown-basic" + props.idPart} disabled={props.usersBeingImported}>
              {props.pageSize}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {props.pageOptions.map(pageOption => (
                <Dropdown.Item
                  onClick={() => props.onPageSizeChange(pageOption)}
                  key={pageOption}
                  active={pageOption === props.pageSize}
                >
                  {pageOption}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col">
          <nav aria-label="users pagination navigation">
            <ul className="pagination">
              {createPaginationItem(
                Math.max(0, props.currentPage - 1),
                props.currentPage === 0,
                "chevron-left",
                props,
                "prev"
              )}
              {props.currentPage >= 3 && createPaginationItem(0, false, null, props)}
              {props.currentPage >= 3 && dots}
              {props.currentPage >= 2 && createPaginationItem(props.currentPage - 2, false, null, props)}
              {props.currentPage >= 1 && createPaginationItem(props.currentPage - 1, false, null, props)}
              {createPaginationItem(props.currentPage, false, null, props)}
              {lastPage - props.currentPage >= 1 && createPaginationItem(props.currentPage + 1, false, null, props)}
              {lastPage - props.currentPage >= 2 && createPaginationItem(props.currentPage + 2, false, null, props)}
              {lastPage - props.currentPage >= 3 && dots}
              {lastPage - props.currentPage >= 3 && createPaginationItem(lastPage, false, null, props)}
              {createPaginationItem(
                Math.min(lastPage, props.currentPage + 1),
                props.currentPage === lastPage,
                "chevron-right",
                props,
                "next"
              )}
            </ul>
          </nav>
        </div>
        <div className="col d-none d-md-block">
          Displaying {resultsFrom} - {resultsTo} of {props.total} results
        </div>
      </div>
    </div>
  );
};
PaginationCA.propTypes = {
  pageOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSelect: PropTypes.func.isRequired,
  idPart: PropTypes.string.isRequired,
  onPageSizeChange: PropTypes.func.isRequired
};

export default PaginationCA;

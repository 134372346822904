import _ from "lodash";
import {ThunkDispatch} from "redux-thunk";
import {
  fetchConfiguration,
  fetchConfigurationMeta,
  changeValue,
  saveChanges,
  showConfirmCancel
} from "../../store/configuration/actions";
import {ConfigurationMetadataPart, ConfigurationPart, ConfigurationState} from "../../store/configuration/types";
import {ValueType} from "../../components/common/input/types";
import {getChangedSettings} from "../../store/configuration/utils";

export default function getSharedConfiguration(dispatch: ThunkDispatch<{}, {}, any>) {
  return {
    loadData: async () => {
      dispatch(fetchConfiguration());
      dispatch(fetchConfigurationMeta());
    },
    showConfirmCancel: (show: boolean) => {
      dispatch(showConfirmCancel(show));
    },
    changeValue: (mdPart: ConfigurationMetadataPart, name: string, value: ValueType) => {
      dispatch(changeValue(mdPart, name, value));
    },
    saveChanges: async (configPart: ConfigurationPart, configState: ConfigurationState) => {
      const changedSettings = getChangedSettings(configPart, configState);
      if (_.isArray(changedSettings) && changedSettings.length > 0) {
        await dispatch(saveChanges([{...configPart, settings: changedSettings}]));
        dispatch(fetchConfiguration());
      }
    }
  };
}

import React from "react";
import _ from "lodash";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ScreenExtensionsProps} from "../../types";
import {ConfigField} from "../../../../../store/screenExtensions/types";
import "./configurableFieldsTreeView.scss";

library.add(faMinus);
library.add(faPlus);

export default function ConfigurableFieldsTreeView(props: ScreenExtensionsProps) {
  const toggleExpanded = (key: string) => {
    props.toggleExpanded(key, !props.toggleTreeView[key]);
  };

  const openEditField = (field: ConfigField, screen: string, section: string) => {
    const activeConfig = getActiveConfigFieldObj(field, screen, section);
    props.changeActiveConfigFields(activeConfig);
    props.showAddEditConfigField(true, true);
  };
  const getActiveConfigFieldObj = (field: ConfigField, screen: string, section: string) => {
    return {
      fieldName: field.fieldName,
      label: field.label,
      defaultReferenceValue: field.defaultReferenceValue,
      order: field.order,
      hidden: field.hidden,
      type: field.type,
      widget: field.widget,
      selectionSource: field.selectionSource,
      entityName: field.entity && field.entity.name,
      entityValue: field.entity && field.entity.valueField,
      entityId: field.entity && field.entity.idField,
      parentDropDownListField: field.entity && field.entity.parentDropDownListField,
      filterField: field.entity && field.entity.filterField,
      editable: field.editable,
      max: field.validationRules && field.validationRules.max,
      min: field.validationRules && field.validationRules.min,
      required: field.validationRules && field.validationRules.required,
      script: field.validationRules && field.validationRules.script,
      requiredOnWoCompletion: field.validationRules && field.validationRules.requiredOnWoCompletion,
      searchType: field.searchType,
      screen,
      section
    };
  };

  let confirmDeletePopup = (
    <Modal
      show={true}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.showConfirmCancel(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Confirm that you want to delete cofigurable field?</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => props.showConfirmCancel(false)}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            props.showConfirmCancel(false);
            props.deleteConfigField(props.activeConfigField);
          }}
        >
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );

  const screensWithCustomFields = _.filter(props.configurableFields, screen =>
    _.some(screen.groups, group => group.fields.length > 0)
  );

  const screens = _.map(screensWithCustomFields, config => {
    return (
      <ul key={config.screen}>
        <li>
          <span
            className="mr-2 toggle-button"
            onClick={() => {
              toggleExpanded(config.screen);
            }}
          >
            {props.toggleTreeView[config.screen] ? <FontAwesomeIcon icon="minus" /> : <FontAwesomeIcon icon="plus" />}
          </span>
          <span>{config.screen}</span>
          {props.toggleTreeView[config.screen] && (
            <ul>
              {_.map(config.groups, group => {
                return (
                  group.fields.length > 0 && (
                    <li key={group.id}>
                      <span
                        className="mr-2 toggle-button"
                        onClick={() => {
                          toggleExpanded(config.screen + group.id);
                        }}
                      >
                        {props.toggleTreeView[config.screen + group.id] ? (
                          <FontAwesomeIcon icon="minus" />
                        ) : (
                          <FontAwesomeIcon icon="plus" />
                        )}
                      </span>
                      <span>{group.id}</span>
                      {props.toggleTreeView[config.screen + group.id] && (
                        <ul className="ml-3">
                          {_.map(group.fields, field => {
                            return (
                              <li key={field.fieldName} className="field-block">
                                <span
                                  className="field-name"
                                  onClick={() => {
                                    openEditField(field, config.screen, group.id);
                                  }}
                                >
                                  {field.fieldName}
                                </span>
                                <span
                                  className="field-delete pl-2"
                                  onClick={() => {
                                    let activeConfigField = getActiveConfigFieldObj(field, config.screen, group.id);
                                    props.changeActiveConfigFields(activeConfigField);
                                    props.showConfirmCancel(true);
                                  }}
                                >
                                  X
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  )
                );
              })}
            </ul>
          )}
        </li>
      </ul>
    );
  });
  return (
    <>
      {props.showConfirmationDialog && confirmDeletePopup}
      <div className="tree-view">{screens}</div>
    </>
  );
}

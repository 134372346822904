import _ from "lodash";
import {AppState} from "../rootReducer";

import {
  SET_USERS_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_QUERY,
  USERS_PAGE_REQUEST,
  USERS_PAGE_SUCCESS,
  USERS_PAGE_FAILURE,
  IMPORT_USERS_REQUEST,
  IMPORT_USERS_SUCCESS,
  IMPORT_USERS_FAILURE,
  SET_INCLUDE_DISABLED
} from "./types";

export const setUsersPage = function(page: number) {
  return {
    type: SET_USERS_PAGE,
    page
  };
};

export function setPageSize(pageSize: number) {
  return {
    type: SET_PAGE_SIZE,
    pageSize
  };
}
export function setSearchQuery(query: string) {
  return {
    type: SET_SEARCH_QUERY,
    query
  };
}
export function setIncludeDisabledAction(includeDisabled: boolean) {
  return {
    type: SET_INCLUDE_DISABLED,
    includeDisabled
  };
}

export function fetchUsersIfNeeded(page?: number, pageSize?: number, query?: string) {
  return {
    types: [USERS_PAGE_REQUEST, USERS_PAGE_SUCCESS, USERS_PAGE_FAILURE],
    getURL: (state: AppState) => {
      const pageActual = _.isUndefined(page) ? state.users.page : page;
      const pageSizeActual = _.isUndefined(pageSize) ? state.users.pageSize : pageSize;
      const queryActual = _.isUndefined(query) ? state.users.query : query;
      return (
        `api/user?sortedBy=loginId&sortDirection=asc&includeDisabled=${state.users.includeDisabled}` +
        `&page=${pageActual}&size=${pageSizeActual}&q=${queryActual}`
      );
    },
    shouldPerformRequest: (state: AppState) => !state.users.isLoading
  };
}

export function importUsers() {
  return {
    types: [IMPORT_USERS_REQUEST, IMPORT_USERS_SUCCESS, IMPORT_USERS_FAILURE],
    showPopup: false,
    getURL: () => `api/user/import`,
    method: "POST"
  };
}

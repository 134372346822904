import _ from "lodash";
import React, {useEffect, useState} from "react";
import Dropdown from "../../pages/configuration/widgets/Dropdown";
import Switcher from "../../pages/configuration/widgets/Switcher";
import {ScreenExtensionsProps} from "../../pages/screenExtensions/types";
import {ValueType} from "../input/types";

interface SearchableProps extends ScreenExtensionsProps {
  onChange: (name: string, value: ValueType) => void;
}
export default function Searchable(props: SearchableProps) {
  const [searchableEnabled, setSearchableEnabled] = useState(false);

  useEffect(() => {
    setSearchableEnabled(!!_.get(props.activeConfigField, "searchType", false));
  }, [props.showAddEditConfigFieldDialog]);

  const searchTypes: string[] = ["EXACT", "WILDCARD"];

  return (
    <>
      <div className="form-group">
        <Switcher
          onChange={val => {
            setSearchableEnabled(!!val);
            if (!val) {
              props.onChange("searchType", null);
            }
          }}
          name="Searchable"
          title="Searchable"
          disabled={false}
          isValid={true}
          type="SLIDER"
          value={searchableEnabled}
        />
      </div>
      {searchableEnabled && (
        <div className="form-group">
          <Dropdown
            onChange={val => {
              props.onChange("searchType", val);
            }}
            name="Search Type"
            title="Search Type"
            disabled={false}
            isValid={true}
            placeholder="Search Type"
            value={_.get(props.activeConfigField, "searchType", null)}
            options={searchTypes}
          />
        </div>
      )}
    </>
  );
}

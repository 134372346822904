export const SET_USERS_PAGE = "SET_USERS_PAGE";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_INCLUDE_DISABLED = "SET_INCLUDE_DISABLED";

export const USERS_PAGE_REQUEST = "USERS_PAGE_REQUEST";
export const USERS_PAGE_SUCCESS = "USERS_PAGE_SUCCESS";
export const USERS_PAGE_FAILURE = "USERS_PAGE_FAILURE";

export const IMPORT_USERS_REQUEST = "IMPORT_USERS_REQUEST";
export const IMPORT_USERS_SUCCESS = "IMPORT_USERS_SUCCESS";
export const IMPORT_USERS_FAILURE = "IMPORT_USERS_FAILURE";

export interface User {
  id: number;
  displayName: string;
  loginId: string;
}
export interface UsersResponse {
  content: User[];
  totalElements: number;
  totalPages: number;
}
export interface UsersState {
  page: number;
  total: number;
  pageSize: number;
  pageOptions: number[];
  users: User[];
  query: string;
  errorMessage: string;
  isLoading: boolean;
  usersBeingImported: boolean;
  includeDisabled: boolean;
}

export interface SetUsersPageAction {
  type: typeof SET_USERS_PAGE;
  page: number;
}
export interface SetPageSizeAction {
  type: typeof SET_PAGE_SIZE;
  pageSize: number;
}
export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY;
  query: string;
}
export interface SetIncludeDisabledAction {
  type: typeof SET_INCLUDE_DISABLED;
  includeDisabled: boolean;
}
export interface RequestUsersAction {
  type: typeof USERS_PAGE_REQUEST;
}
export interface ReceiveUsersAction {
  type: typeof USERS_PAGE_SUCCESS;
  data: UsersResponse;
}
export interface FailUsersAction {
  type: typeof USERS_PAGE_FAILURE;
  errorMessage: string;
}

export interface importUsersRequestAction {
  type: typeof IMPORT_USERS_REQUEST;
}

export interface importUsersSuccessAction {
  type: typeof IMPORT_USERS_SUCCESS;
}

export interface importUsersFailureAction {
  type: typeof IMPORT_USERS_FAILURE;
  errorMessage: string;
}

export type UsersActionTypes =
  | SetUsersPageAction
  | RequestUsersAction
  | ReceiveUsersAction
  | FailUsersAction
  | SetSearchQueryAction
  | SetIncludeDisabledAction
  | SetPageSizeAction
  | importUsersSuccessAction
  | importUsersFailureAction
  | importUsersRequestAction;

import {
  CONFIGURATION_SUCCESS,
  GENERIC_REQUEST_FAILURE,
  GENERIC_REQUEST_START,
  CONFIGURATION_METADATA_SUCCESS,
  SELECT_CONFIG_PART,
  SAVE_CHANGES_SUCCESS,
  CHANGE_VALUE,
  TAB_LOCK_CHANGE,
  SHOW_CONFIRM_CANCEL,
  SHOW_ADVANCED_CONFIGS,
  CLEAR_DIRTY,
  ConfigurationPart,
  ConfigurationMetadataPart,
  ConfigurationResponseSchema,
  ConfigurationMetaResponseSchema
} from "./types";
import {ValueType} from "../../components/common/input/types";

export function fetchConfiguration() {
  return {
    types: [GENERIC_REQUEST_START, CONFIGURATION_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ConfigurationResponseSchema,
    getURL: () => `api/configurations`
  };
}
export function fetchConfigurationMeta() {
  return {
    types: [GENERIC_REQUEST_START, CONFIGURATION_METADATA_SUCCESS, GENERIC_REQUEST_FAILURE],
    responseSchema: ConfigurationMetaResponseSchema,
    getURL: () => `api/configurationMetadata`
  };
}
export function setConfigPart(part: string) {
  return {
    type: SELECT_CONFIG_PART,
    part
  };
}
export function showConfirmCancel(show: boolean) {
  return {
    type: SHOW_CONFIRM_CANCEL,
    show
  };
}
export function showAdvancedConfigs(show: boolean) {
  return {
    type: SHOW_ADVANCED_CONFIGS,
    show
  };
}
export function clearDirty(tabName: string) {
  return {
    type: CLEAR_DIRTY,
    tabName
  };
}
export function changeValue(mdPart: ConfigurationMetadataPart, name: string, value: ValueType) {
  return {
    type: CHANGE_VALUE,
    mdPart,
    name,
    value
  };
}
export function saveChanges(payload: ConfigurationPart[]) {
  return {
    types: [GENERIC_REQUEST_START, SAVE_CHANGES_SUCCESS, GENERIC_REQUEST_FAILURE],
    payload,
    getURL: () => `api/configurations`,
    method: "PUT"
  };
}

export function changeTabLock(tabName: string, locked: boolean) {
  return {
    type: TAB_LOCK_CHANGE,
    tabName,
    locked
  };
}

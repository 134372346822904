import {
  SET_USERS_PAGE,
  SET_PAGE_SIZE,
  SET_SEARCH_QUERY,
  SET_INCLUDE_DISABLED,
  USERS_PAGE_REQUEST,
  USERS_PAGE_SUCCESS,
  USERS_PAGE_FAILURE,
  IMPORT_USERS_REQUEST,
  IMPORT_USERS_SUCCESS,
  IMPORT_USERS_FAILURE,
  UsersActionTypes,
  UsersState
} from "./types";

const defaultUsersState: UsersState = {
  page: 0,
  total: 0,
  pageSize: 10,
  pageOptions: [10, 25, 50],
  query: "",
  users: [],
  errorMessage: "",
  isLoading: false,
  usersBeingImported: false,
  includeDisabled: false
};
export default function users(state = defaultUsersState, action: UsersActionTypes) {
  switch (action.type) {
    case SET_USERS_PAGE:
      return {...state, page: action.page};
    case SET_SEARCH_QUERY:
      return {...state, query: action.query};
    case SET_PAGE_SIZE:
      return {...state, pageSize: action.pageSize};
    case USERS_PAGE_REQUEST:
      return {...state, errorMessage: "", isLoading: true};
    case USERS_PAGE_SUCCESS:
      return {
        ...state,
        users: action.data.content,
        total: action.data.totalElements,
        isLoading: false
      };
    case USERS_PAGE_FAILURE:
      return {...state, errorMessage: action.errorMessage, isLoading: false};
    case IMPORT_USERS_REQUEST:
      return {
        ...state,
        errorMessage: "",
        usersBeingImported: true
      };
    case IMPORT_USERS_SUCCESS:
      return {...state, usersBeingImported: false};
    case SET_INCLUDE_DISABLED:
      return {...state, includeDisabled: action.includeDisabled};
    case IMPORT_USERS_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        usersBeingImported: false
      };
    default:
      return state;
  }
}

import _ from "lodash";
import {
  CHANGE_LABEL_TRANSLATIONS,
  CHANGE_ACTIVE_TAB,
  SHOW_ADD_EDIT_CONFIG_LABEL,
  CHANGE_ACTIVE_LABEL,
  ScreenExtensionsTypes,
  ScreenExtensionsState,
  GET_CONFIGURABLE_LABELS_SUCCESS,
  GENERIC_REQUEST_FAILURE,
  GENERIC_REQUEST_START,
  ADD_CHANGE_CONFIG_LABEL_SUCCESS,
  ADD_CHANGE_CONFIG_LABEL_FAILURE,
  DELETE_CONFIG_LABEL_SUCCESS,
  GET_SCREEN_EXTENSIONS_CONFIG_SUCCESS,
  SELECT_SCREEN_CHANGE,
  SELECT_BUTTON_SCREEN_CHANGE,
  SET_ACTIVE_SCREEN_BTNS_CONFIG,
  SHOW_ADD_EDIT_CONFIGURABLE_FIELD,
  CHANGE_CONFIG_FIELD,
  HIDE_INFO_MESSAGE,
  ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS,
  ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE,
  DELETE_CONFIGURABLE_FIELD_SUCCESS,
  DELETE_CONFIGURABLE_BUTTON_SUCCESS,
  ADD_MENU_BUTTON_ON_SCREEN_FAILURE,
  GET_SCREEN_EXTENSIONS_SUCCESS,
  TOGGLE_CUSTOM_TREE_VIEW,
  CHANGE_ACTIVE_CONFIG_FIELDS,
  SET_ACTIVE_CONFIG_BUTTON,
  SET_CONFIG_BUTTON_TO_DELETE,
  CLEAR_SCREEN_CONFIG_WORKING_COPY,
  CLEAR_CONFIG_CREATE_ERROR_MESSAGE,
  SET_SCREEN_CONFIG_WORKING_COPY,
  EDIT_SCREEN_CONFIGURATION_START,
  EDIT_SCREEN_CONFIGURATION_SUCCESS,
  EDIT_SCREEN_CONFIGURATION_FAILURE,
  SCREEN_EDITOR_CONFIG_UPDATED,
  ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START,
  SET_CONFIG_FIELD_TO_ADD,
  SET_CONFIG_FIELD_TO_EDIT,
  SET_CONFIG_FIELD_TO_DELETE,
  configurableFieldsConfig,
  configurableButtonsConfig,
  receivedConfigurableFieldsConfig
} from "./types";

const defaultScreenExtensionsState: ScreenExtensionsState = {
  activeTab: "",
  activeLabel: undefined,
  editMode: false,
  configurableLabels: [],
  configurableFieldsConfig: {},
  configurableButtonsConfig: [],
  configurableFields: [],
  screenConfigWorkingCopy: {
    screen: "",
    groups: [
      {
        id: "",
        fields: [],
        actions: []
      }
    ]
  },
  showAddEditConfigLabelDialog: false,
  showAddEditConfigFieldDialog: false,
  showInfoMessage: false,
  selectScreen: {screen: "Select screen"},
  activeConfigField: {},
  activeConfigButton: {},
  configButtonToDelete: {},
  buttonsConfigOnSelectedScreen: {
    id: "",
    groups: [],
    actions: []
  },
  errorMessage: "",
  configCreateErrorMessage: "",
  configLabelErrorMessage: "",
  toggleTreeView: {},
  editConfig: false,
  screenEditorHasNotSavedChanges: false
};
export default function screenExtensions(state = defaultScreenExtensionsState, action: ScreenExtensionsTypes) {
  switch (action.type) {
    case CHANGE_LABEL_TRANSLATIONS: {
      if (_.isUndefined(state.activeLabel)) {
        return state;
      }
      return {
        ...state,
        activeLabel: {
          ...state.activeLabel,
          [action.name]: action.value
        }
      };
    }
    case CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.activeTab
      };
    }
    case CHANGE_ACTIVE_LABEL: {
      return {
        ...state,
        activeLabel: action.label
      };
    }
    case SHOW_ADD_EDIT_CONFIG_LABEL: {
      return {
        ...state,
        configLabelErrorMessage: "",
        showAddEditConfigLabelDialog: action.show,
        editMode: action.editMode
      };
    }
    case GENERIC_REQUEST_START: {
      return {...state, errorMessage: ""};
    }
    case GENERIC_REQUEST_FAILURE: {
      return {...state, errorMessage: action.errorMessage};
    }
    case GET_CONFIGURABLE_LABELS_SUCCESS: {
      return {
        ...state,
        configurableLabels: action.data
      };
    }
    case ADD_CHANGE_CONFIG_LABEL_SUCCESS: {
      return {
        ...state,
        activeLabel: undefined,
        showAddEditConfigLabelDialog: false
      };
    }

    case ADD_CHANGE_CONFIG_LABEL_FAILURE: {
      return {
        ...state,
        configLabelErrorMessage: JSON.parse(action.errorMessage).data.errorMessage
      };
    }
    case DELETE_CONFIG_LABEL_SUCCESS: {
      return state;
    }
    case GET_SCREEN_EXTENSIONS_CONFIG_SUCCESS: {
      let fieldsConfig: configurableFieldsConfig = {};
      let buttonsConfig: configurableButtonsConfig[] = [];
      _.forEach(action.data, (fieldsConfigOnScreen: receivedConfigurableFieldsConfig) => {
        fieldsConfig[fieldsConfigOnScreen.id] = fieldsConfigOnScreen.groups;
        if (fieldsConfigOnScreen.actions) {
          buttonsConfig.push(fieldsConfigOnScreen);
        }
      });
      return {
        ...state,
        configurableFieldsConfig: fieldsConfig,
        configurableButtonsConfig: buttonsConfig
      };
    }
    case SELECT_SCREEN_CHANGE: {
      return {
        ...state,
        selectScreen: {
          ...state.selectScreen,
          [action.name]: action.value
        }
      };
    }
    case SELECT_BUTTON_SCREEN_CHANGE: {
      return {
        ...state,
        activeConfigButton: {
          ...state.activeConfigButton,
          [action.name]: action.value
        },
        configCreateErrorMessage: ""
      };
    }
    case SET_ACTIVE_SCREEN_BTNS_CONFIG: {
      return {
        ...state,
        buttonsConfigOnSelectedScreen: action.config
      };
    }
    case SHOW_ADD_EDIT_CONFIGURABLE_FIELD: {
      return {
        ...state,
        showAddEditConfigFieldDialog: action.show,
        configCreateErrorMessage: "",
        editConfig: action.editConfig
      };
    }
    case SET_SCREEN_CONFIG_WORKING_COPY: {
      const receivedConfig = _.find(state.configurableFields, {screen: action.screen});
      const screenConfig = {
        screen: action.screen,
        groups: _.map(state.configurableFieldsConfig[action.screen], group => {
          const groupConfig = receivedConfig ? _.find(receivedConfig.groups, {id: group}) : undefined;
          return {
            id: group,
            actions: groupConfig ? [...groupConfig.actions] : [],
            fields: groupConfig ? [...groupConfig.fields] : []
          };
        })
      };
      return {
        ...state,
        screenConfigWorkingCopy: screenConfig
      };
    }
    case SCREEN_EDITOR_CONFIG_UPDATED: {
      return {
        ...state,
        screenEditorHasNotSavedChanges: action.updated
      };
    }
    case SET_CONFIG_FIELD_TO_ADD: {
      const groups = state.screenConfigWorkingCopy.groups;
      let groupToUpdateIndex = _.findIndex(groups, {id: action.group});
      if (groupToUpdateIndex >= 0) {
        const updatedGroups = _.map(groups, (group, index) => {
          if (index === groupToUpdateIndex) {
            return {
              id: group.id,
              actions: group.actions,
              fields: [...group.fields, action.configField]
            };
          } else {
            return group;
          }
        });
        return {
          ...state,
          screenConfigWorkingCopy: {
            ...state.screenConfigWorkingCopy,
            groups: updatedGroups
          }
        };
      } else {
        return {...state};
      }
    }
    case SET_CONFIG_FIELD_TO_EDIT: {
      const groups = state.screenConfigWorkingCopy.groups;
      let groupToUpdateIndex = _.findIndex(groups, {id: action.group});
      let fieldToUpdateIndex =
        groupToUpdateIndex >= 0
          ? _.findIndex(groups[groupToUpdateIndex].fields, {
              fieldName: action.configField.fieldName
            })
          : -1;
      if (fieldToUpdateIndex >= 0) {
        const updatedGroups = _.map(groups, (group, index) => {
          if (index === groupToUpdateIndex) {
            return {
              id: group.id,
              actions: group.actions,
              fields: _.map(group.fields, (field, index) => {
                return index === fieldToUpdateIndex ? action.configField : field;
              })
            };
          } else {
            return group;
          }
        });
        return {
          ...state,
          screenConfigWorkingCopy: {
            ...state.screenConfigWorkingCopy,
            groups: updatedGroups
          }
        };
      } else {
        return {...state};
      }
    }
    case SET_CONFIG_FIELD_TO_DELETE: {
      const groups = state.screenConfigWorkingCopy.groups;
      let groupToUpdateIndex = _.findIndex(groups, {id: action.field.section});
      let fieldToDeleteIndex =
        groupToUpdateIndex >= 0
          ? _.findIndex(groups[groupToUpdateIndex].fields, {
              fieldName: action.field.fieldName
            })
          : -1;
      if (fieldToDeleteIndex >= 0) {
        const updatedGroups = _.map(groups, (group, index) => {
          if (index === groupToUpdateIndex) {
            return {
              id: group.id,
              actions: group.actions,
              fields: [...group.fields.slice(0, fieldToDeleteIndex), ...group.fields.slice(fieldToDeleteIndex + 1)]
            };
          } else {
            return group;
          }
        });
        return {
          ...state,
          screenConfigWorkingCopy: {
            ...state.screenConfigWorkingCopy,
            groups: updatedGroups
          }
        };
      } else {
        return {...state};
      }
    }
    case CLEAR_SCREEN_CONFIG_WORKING_COPY: {
      return {
        ...state,
        screenEditorHasNotSavedChanges: false,
        screenConfigWorkingCopy: {
          screen: "",
          groups: [
            {
              id: "",
              fields: [],
              actions: []
            }
          ]
        }
      };
    }
    case EDIT_SCREEN_CONFIGURATION_START: {
      return {
        ...state,
        configCreateErrorMessage: ""
      };
    }
    case EDIT_SCREEN_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        showInfoMessage: true
      };
    }
    case EDIT_SCREEN_CONFIGURATION_FAILURE: {
      return {
        ...state,
        configCreateErrorMessage: JSON.parse(action.errorMessage).data.errorMessage
      };
    }
    case CLEAR_CONFIG_CREATE_ERROR_MESSAGE: {
      return {
        ...state,
        configCreateErrorMessage: ""
      };
    }
    case CHANGE_CONFIG_FIELD: {
      return {
        ...state,
        activeConfigField: {
          ...state.activeConfigField,
          [action.name]: action.value
        }
      };
    }
    case ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START: {
      return {...state, configCreateErrorMessage: ""};
    }
    case ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS: {
      return {
        ...state,
        showAddEditConfigFieldDialog: false,
        activeConfigField: {},
        showInfoMessage: true
      };
    }
    case HIDE_INFO_MESSAGE: {
      return {
        ...state,
        showInfoMessage: false
      };
    }
    case ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE: {
      return {
        ...state,
        configCreateErrorMessage: JSON.parse(action.errorMessage).data.errorMessage
      };
    }
    case DELETE_CONFIGURABLE_FIELD_SUCCESS: {
      return {
        ...state,
        activeConfigField: {},
        showInfoMessage: true
      };
    }
    case DELETE_CONFIGURABLE_BUTTON_SUCCESS: {
      return {
        ...state
      };
    }
    case ADD_MENU_BUTTON_ON_SCREEN_FAILURE: {
      return {
        ...state,
        configCreateErrorMessage: JSON.parse(action.errorMessage).data.errorMessage
      };
    }
    case SET_ACTIVE_CONFIG_BUTTON: {
      return {
        ...state,
        activeConfigButton: action.config
      };
    }
    case SET_CONFIG_BUTTON_TO_DELETE: {
      return {
        ...state,
        configButtonToDelete: action.config
      };
    }
    case GET_SCREEN_EXTENSIONS_SUCCESS: {
      return {
        ...state,
        configurableFields: action.data
      };
    }
    case TOGGLE_CUSTOM_TREE_VIEW: {
      return {
        ...state,
        toggleTreeView: {
          ...state.toggleTreeView,
          [action.key]: action.value
        }
      };
    }
    case CHANGE_ACTIVE_CONFIG_FIELDS: {
      return {
        ...state,
        activeConfigField: action.config
      };
    }
    default:
      return state;
  }
}

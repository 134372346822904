import {library} from "@fortawesome/fontawesome-svg-core";
import {faLink, faCog, faGlobe, faDatabase, faUser} from "@fortawesome/free-solid-svg-icons";
import ConfigurationPage from "../containers/connectedConfiguration";
import DBReloadPage from "../containers/connectedDBReload";
import ConnectedUsers from "../containers/connectedUsers";
import IntegrationsPage from "../components/pages/integrations";
import ScreenExtensions from "../containers/connectedScreenExtensions";
import AmrPage from "../containers/connectedAmr";

library.add(faLink);
library.add(faCog);
library.add(faGlobe);
library.add(faDatabase);
library.add(faUser);

const enableAppDesigner = process.env.REACT_APP_ENABLE_APP_DESIGNER === "true";

const routes = [
  {
    path: "/configuration",
    title: "Connect2 Assets",
    main: AmrPage,
    icon: "link"
  },
  {
    path: "/integrations",
    title: "Integrations",
    main: IntegrationsPage,
    icon: "link"
  },
  {
    path: "/appConfiguration",
    title: "App Configuration",
    main: ConfigurationPage,
    icon: "cog"
  },
  {
    path: "/dbReload",
    title: "DB Reload",
    main: DBReloadPage,
    icon: "database"
  },
  {
    path: "/pageUsers",
    title: "Users",
    main: ConnectedUsers,
    icon: "user"
  },
  {
    path: enableAppDesigner ? "/appDesigner" : "/formEditor",
    title: enableAppDesigner ? "App Designer" : "Form Editor",
    main: ScreenExtensions,
    icon: "cog"
  }
];

export default routes;

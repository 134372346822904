import React from "react";
import _ from "lodash";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ScreenExtensionsProps} from "../../types";
import "./configurableButtonsTreeView.scss";

library.add(faMinus);
library.add(faPlus);

export default function ConfigurableFieldsTreeView(props: ScreenExtensionsProps) {
  const toggleExpanded = (key: string) => {
    props.toggleExpanded(key, !props.toggleTreeView[key]);
  };

  let confirmDeletePopup = (
    <Modal
      show={true}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.showConfirmCancel(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Confirm that you want to delete button?</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => props.showConfirmCancel(false)}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            props.showConfirmCancel(false);
            props.deleteConfigButton(props.configButtonToDelete);
          }}
        >
          Yes
        </button>
      </Modal.Footer>
    </Modal>
  );

  const screensWithCustomButtons = _.filter(props.configurableFields, screen =>
    _.some(screen.groups, group => group.actions.length > 0)
  );

  const screens = _.map(screensWithCustomButtons, config => {
    return (
      <ul key={config.screen}>
        <li>
          <span
            className="mr-2 toggle-button"
            onClick={() => {
              toggleExpanded(config.screen);
            }}
          >
            {props.toggleTreeView[config.screen] ? <FontAwesomeIcon icon="minus" /> : <FontAwesomeIcon icon="plus" />}
          </span>
          <span>{config.screen}</span>
          {props.toggleTreeView[config.screen] && (
            <ul>
              {_.map(config.groups, group => {
                return (
                  <li key={group.id}>
                    <span
                      className="mr-2 toggle-button"
                      onClick={() => {
                        toggleExpanded(config.screen + group.id);
                      }}
                    >
                      {props.toggleTreeView[config.screen + group.id] ? (
                        <FontAwesomeIcon icon="minus" />
                      ) : (
                        <FontAwesomeIcon icon="plus" />
                      )}
                    </span>
                    <span>{group.id}</span>
                    {props.toggleTreeView[config.screen + group.id] && (
                      <ul className="ml-3">
                        {_.map(group.actions, action => {
                          return (
                            <li key={action.action} className="action-block">
                              <span className="action-name">{action.action}</span>
                              <span
                                className="action-delete pl-2"
                                onClick={() => {
                                  props.showConfirmCancel(true);
                                  props.setConfigButtonToDelete({
                                    id: config.screen,
                                    group: group.id,
                                    action: action.action
                                  });
                                }}
                              >
                                X
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      </ul>
    );
  });
  return (
    <>
      {props.showConfirmationDialog && confirmDeletePopup}
      <div className="tree-view">{screens}</div>
    </>
  );
}

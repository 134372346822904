import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {ScreenExtensionsProps} from "../../types";
import Switcher from "../../../configuration/widgets/Switcher";
import Dropdown from "../../../configuration/widgets/Dropdown";
import {ValueType} from "../../../../common/input/types";
import _ from "lodash";
import {FieldSupportConfig} from "../../../../../store/sharedStore/types";
import DropdownConfig from "./DropdownConfig";
import Searchable from "../../../../common/Searchable/Searchable";

export default function AddConfigurableField(props: ScreenExtensionsProps) {
  const appDesignerEnabled = process.env.REACT_APP_ENABLE_APP_DESIGNER === "true";

  const [formValidationError, setValidationError] = useState("");

  const addConfigurableField = (event: React.FormEvent<HTMLFormElement>) => {
    const groupConfig = _.find(props.screenConfigWorkingCopy.groups, {id: props.selectScreen["section"]});
    if (
      !props.editConfig &&
      groupConfig &&
      _.some(groupConfig.fields, {fieldName: props.activeConfigField["fieldName"]})
    ) {
      setValidationError("Sorry, but the entered field name is already added in a section");
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    let configField = {
      fieldName: props.activeConfigField["fieldName"],
      label: props.activeConfigField["label"],
      defaultReferenceValue: props.activeConfigField["defaultReferenceValue"],
      hidden: props.activeConfigField["hidden"],
      order: props.activeConfigField["order"],
      type: props.activeConfigField["type"],
      widget: props.activeConfigField["widget"],
      editable: props.activeConfigField["editable"],
      searchType: props.location.state.screen === "assetSearch" ? props.activeConfigField["searchType"] : undefined,
      selectionSource:
        !!props.activeConfigField["selectionSource"] &&
        (props.activeConfigField["widget"] === "dropdown" || props.activeConfigField["widget"] === "autocomplete")
          ? props.activeConfigField["selectionSource"]
          : undefined,
      entity:
        (props.activeConfigField["widget"] === "dropdown" || props.activeConfigField["widget"] === "autocomplete") &&
        !props.activeConfigField["selectionSource"]
          ? {
              name: props.activeConfigField["entityName"],
              valueField: props.activeConfigField["entityValue"],
              idField: props.activeConfigField["entityId"],
              parentDropDownListField: props.activeConfigField["parentDropDownListField"],
              filterField: props.activeConfigField["filterField"]
            }
          : undefined,
      validationRules: {
        max: props.activeConfigField["max"],
        min: props.activeConfigField["min"],
        required: props.activeConfigField["required"],
        script: props.activeConfigField["script"],
        requiredOnWoCompletion: props.activeConfigField["requiredOnWoCompletion"]
      }
    };

    if (
      (configField.widget === "text" || configField.widget === "textarea") &&
      props.activeConfigField.defaultReferenceValue &&
      props.activeConfigField.defaultReferenceValue.indexOf(".") <= 0
    ) {
      setValidationError("Entity type and property should be separated by dot (.)");
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (Number(props.activeConfigField.order) < 0) {
      setValidationError('"Order" value cannot be negative');
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    let screen = props.selectScreen["screen"];
    let group = props.selectScreen["section"];
    const validationRules = _.pickBy(configField.validationRules, _.identity);
    if (Object.keys(validationRules).length === 0 || !configField.editable) {
      delete configField.validationRules;
    }
    if (configField.widget !== "text" && configField.widget !== "textarea") {
      delete configField.defaultReferenceValue;
      if (configField.validationRules) {
        delete configField.validationRules.min;
        delete configField.validationRules.max;
      }
    }

    if (props.editConfig) {
      screen = props.activeConfigField["screen"];
      group = props.activeConfigField["section"];
      if (appDesignerEnabled) {
        props.setConfigurableFieldToEdit(configField, screen as string, group as string);
        closeModal();
      } else {
        props.editConfigurableField(configField, screen as string, group as string);
      }
    } else {
      if (appDesignerEnabled) {
        props.setConfigurableFieldToAdd(configField, screen as string, group as string);
        closeModal();
      } else {
        props.addConfigurableField(configField, screen as string, group as string);
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const fieldSupportConfig: FieldSupportConfig = props.fieldSupportConfig;
  const widgets: string[] = Object.keys(fieldSupportConfig);

  const closeModal = () => {
    props.showAddEditConfigField(false, false);
    setValidationError("");
    props.changeActiveConfigFields({});
  };

  const onChange = (name: string, value: ValueType) => {
    if (name === "fieldName") {
      setValidationError("");
    }
    return props.changeConfigValue(name, value);
  };
  return (
    <Modal centered onHide={closeModal} show={props.showAddEditConfigFieldDialog}>
      <Modal.Header closeButton>New Field</Modal.Header>
      <Modal.Body>
        <form onSubmit={addConfigurableField}>
          {formValidationError && <label className="alert-danger">{formValidationError}</label>}
          <div className="form-group d-flex">
            <label className="pl-0 col-md-2">Field name</label>
            <input
              required
              disabled={props.editConfig}
              onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("fieldName", val.currentTarget.value)}
              className="form-control col-md-6"
              type="text"
              value={props.activeConfigField["fieldName"]}
            />
          </div>
          <div className="form-group">
            <Dropdown
              onChange={val => {
                onChange("widget", val);
                onChange("type", fieldSupportConfig[val as string][0]);
              }}
              name="Select Widget"
              title="Select Widget"
              disabled={false}
              isValid={true}
              placeholder="Select Widget"
              value={_.get(props.activeConfigField, "widget", null)}
              options={widgets}
            />
          </div>
          <div className="form-group">
            <Dropdown
              onChange={val => onChange("type", val)}
              name="Select Type"
              title="Select Type"
              disabled={
                !props.activeConfigField.widget ||
                fieldSupportConfig[props.activeConfigField.widget as string].length === 1
              }
              isValid={true}
              placeholder="Select Type"
              value={_.get(props.activeConfigField, "type", null)}
              options={fieldSupportConfig[props.activeConfigField.widget as string]}
            />
          </div>
          {props.activeConfigField.widget && ["dropdown", "autocomplete"].includes(props.activeConfigField.widget) && (
            <DropdownConfig {...props} />
          )}
          {(props.activeConfigField.widget === "text" || props.activeConfigField.widget === "textarea") && (
            <>
              <div className="form-group d-flex">
                <label className="pl-0 col-md-5">Default Reference Value</label>
                <input
                  onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
                    onChange("defaultReferenceValue", val.currentTarget.value)
                  }
                  className="form-control col-md-7"
                  type="text"
                  value={props.activeConfigField["defaultReferenceValue"]}
                />
              </div>
            </>
          )}
          <div className="form-group d-flex">
            <label className="pl-0 col-md-2">Order</label>
            <input
              onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("order", val.currentTarget.value)}
              className="form-control col-md-6"
              type="number"
              value={props.activeConfigField["order"]}
            />
          </div>
          <div className="form-group d-flex">
            <label className="pl-0 col-md-2">Label</label>
            <input
              onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("label", val.currentTarget.value)}
              className="form-control col-md-6"
              type="text"
              value={props.activeConfigField["label"]}
            />
          </div>
          <div className="form-group">
            <Switcher
              onChange={val => {
                onChange("hidden", val);
                onChange("editable", false);
                onChange("required", false);
                onChange("requiredOnWoCompletion", false);
              }}
              name="Hidden"
              title="Hidden"
              disabled={false}
              isValid={true}
              type="SLIDER"
              value={!!_.get(props.activeConfigField, "hidden", null)}
            />
          </div>
          {!props.activeConfigField["hidden"] && (
            <>
              <div className="form-group">
                <Switcher
                  onChange={val => {
                    onChange("editable", val);
                    onChange("required", false);
                    onChange("requiredOnWoCompletion", false);
                  }}
                  name="Editable"
                  title="Editable"
                  disabled={false}
                  isValid={true}
                  type="SLIDER"
                  value={!!_.get(props.activeConfigField, "editable", null)}
                />
              </div>
            </>
          )}
          {props.activeConfigField["editable"] && (
            <>
              <h5>Validation</h5>
              <div className="form-group">
                <Switcher
                  onChange={val => {
                    onChange("required", val);
                    if (!val) {
                      onChange("requiredOnWoCompletion", false);
                    }
                  }}
                  name="Required"
                  title="Required"
                  disabled={false}
                  isValid={true}
                  type="SLIDER"
                  value={!!_.get(props.activeConfigField, "required", null)}
                />
              </div>
              {(props.selectScreen["screen"] === "woDetails" || props.activeConfigField["screen"] === "woDetails") && (
                <>
                  <div className="form-group">
                    <Switcher
                      onChange={val => {
                        onChange("requiredOnWoCompletion", val);
                        if (val) {
                          onChange("required", val);
                        }
                      }}
                      name="requiredOnWoCompletion"
                      title="Required on WO completion/closure"
                      disabled={false}
                      isValid={true}
                      type="SLIDER"
                      value={!!_.get(props.activeConfigField, "requiredOnWoCompletion", null)}
                    />
                  </div>
                </>
              )}
              {(props.activeConfigField.widget === "text" || props.activeConfigField.widget === "textarea") && (
                <>
                  <div className="form-group d-flex">
                    <label className="pl-0 col-md-2">Min</label>
                    <input
                      disabled={false}
                      onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("min", val.currentTarget.value)}
                      className="form-control col-md-6"
                      type="number"
                      value={props.activeConfigField["min"]}
                    />
                  </div>
                  <div className="form-group d-flex">
                    <label className="pl-0 col-md-2">Max</label>
                    <input
                      disabled={false}
                      onChange={(val: React.ChangeEvent<HTMLInputElement>) => onChange("max", val.currentTarget.value)}
                      className="form-control col-md-6"
                      type="number"
                      value={props.activeConfigField["max"]}
                    />
                  </div>
                </>
              )}
              <label>Validation Script</label>
              <div className="form-group">
                <textarea
                  disabled={false}
                  onChange={(val: React.ChangeEvent<HTMLTextAreaElement>) => {
                    onChange("script", val.currentTarget.value);
                  }}
                  className="form-control"
                  value={props.activeConfigField["script"]}
                />
              </div>
            </>
          )}
          {props.location.state && props.location.state.screen === "assetSearch" && (
            <Searchable {...props} onChange={onChange} />
          )}
          <div className="float-right">
            <button type="button" onClick={closeModal} className="btn btn-light">
              Cancel
            </button>
            <button
              type="submit"
              disabled={!props.activeConfigField["widget"] || !props.activeConfigField["type"]}
              className="btn btn-warning"
            >
              {appDesignerEnabled ? "Ok" : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

import Joi from "joi";

export const translationLanguages = {
  "en-us": "English (en-US)",
  "en-uk": "English (en-UK)",
  zh: "Chinese(S) (zh-CN)",
  nl: "Dutch (nl-NL)",
  fr: "French (fr-FR)",
  de: "German (de-DE)",
  it: "Italian (it-IT)",
  ja: "Japanese (ja-JA)",
  ko: "Korean (ko-KO)",
  ms: "Malay (ms-MS)",
  pl: "Polish (pl-PL)",
  pt: "Portuguese (pt-BR)",
  ru: "Russian (ru-RU)",
  si: "Sinhalese (si-SI)",
  es: "Spanish (es-AR)",
  th: "Thai (th-TH)",
  tr: "Turkish (tr-TR)"
};

export interface ConfigurableLabel {
  labelId: string;
  [langName: string]: string | undefined;
}

export interface receivedConfigurableFieldsConfig {
  id: string;
  groups: string[];
  actions: string[];
}

export interface configurableFieldsConfig {
  [key: string]: string[];
}

export interface configurableButtonsConfig {
  id: string;
  groups: string[];
  actions: string[];
}

export interface activeConfigurableButtonConfig {
  [key: string]: string;
}

export interface ConfigField {
  editable: string | undefined;
  fieldName: string | undefined;
  label: string | undefined;
  defaultReferenceValue: string | undefined;
  hidden: string | undefined;
  order: string | undefined;
  type: string | undefined;
  selectionSource: string | undefined;
  entity:
    | {
        name: string | undefined;
        valueField: string | undefined;
        idField: string | undefined;
        parentDropDownListField: string | undefined;
        filterField: string | undefined;
      }
    | undefined;
  validationRules: {
    min: string | undefined;
    max: string | undefined;
    required: string | undefined;
    script: string | undefined;
    requiredOnWoCompletion: string | undefined;
  };
  widget: string | undefined;
  hasBarcodeScan?: boolean;
  fieldDescription?: string;
  searchType: string | undefined;
}

export interface ConfigButton {
  action: string;
  label: string | undefined;
}

export interface ConfigurableField {
  screen: string;
  groups: {
    fields: ConfigField[];
    actions: ConfigButton[];
    id: string;
  }[];
}

export interface ActiveConfigField {
  [key: string]: string | undefined;
}

export interface SectionData {
  name: string;
  columnsResponsive?: boolean;
  fields: ConfigField[];
  actions: ConfigButton[];
}

export interface PreviewData {
  screen: string;
  screenData: SectionData[];
}

export interface ToggleTreeView {
  [key: string]: boolean;
}

export interface ScreenExtensionsState {
  activeTab: string;
  editMode: boolean;
  configurableLabels: ConfigurableLabel[];
  configurableFieldsConfig: configurableFieldsConfig;
  configurableFields: ConfigurableField[];
  screenConfigWorkingCopy: ConfigurableField;
  configurableButtonsConfig: configurableButtonsConfig[];
  selectScreen: ActiveConfigField;
  activeLabel?: ConfigurableLabel;
  showAddEditConfigLabelDialog: boolean;
  showAddEditConfigFieldDialog: boolean;
  showInfoMessage: boolean;
  activeConfigField: ActiveConfigField;
  activeConfigButton: activeConfigurableButtonConfig;
  configButtonToDelete: activeConfigurableButtonConfig;
  buttonsConfigOnSelectedScreen: configurableButtonsConfig;
  errorMessage: string;
  configCreateErrorMessage: string;
  configLabelErrorMessage: string;
  toggleTreeView: ToggleTreeView;
  editConfig: boolean;
  screenEditorHasNotSavedChanges: boolean;
}

export const ConfigurableLabelResponseSchema = Joi.object().pattern(
  Joi.string(),
  Joi.object().pattern(/^[a-zA-Z-]*$/, Joi.string())
);

export const ScreenExtensionsConfigResponseSchema = Joi.array().items(
  Joi.object().keys({
    id: Joi.string(),
    groups: Joi.array().items(Joi.string()),
    actions: [
      Joi.array()
        .items(Joi.string())
        .optional(),
      Joi.allow(null)
    ]
  })
);

export const ScreenExtensionsResponseSchema = Joi.array().items(
  Joi.object().keys({
    groups: Joi.array()
      .items(
        Joi.object().keys({
          fields: Joi.array().items(
            Joi.object().keys({
              editable: Joi.boolean(),
              hidden: Joi.boolean(),
              fieldName: Joi.string(),
              label: Joi.string(),
              order: Joi.number().allow(null),
              type: Joi.string(),
              defaultReferenceValue: Joi.string().allow(null),
              selectionSource: Joi.string().allow(null),
              searchType: Joi.string().allow(null),
              entity: Joi.object()
                .keys({
                  name: Joi.string(),
                  valueField: Joi.string(),
                  idField: Joi.string(),
                  parentDropDownListField: Joi.string().allow(null),
                  filterField: Joi.string().allow(null),
                  parent: Joi.string().allow(null)
                })
                .allow(null),
              validationRules: Joi.object()
                .keys({
                  max: Joi.number(),
                  min: Joi.number(),
                  required: Joi.boolean(),
                  script: Joi.string(),
                  requiredOnWoCompletion: Joi.boolean()
                })
                .allow(null),
              widget: Joi.string()
            })
          ),
          actions: Joi.array().items(
            Joi.object().keys({
              action: Joi.string(),
              label: Joi.string().allow(null)
            })
          ),
          id: Joi.string()
        })
      )
      .allow(null),
    screen: Joi.string(),
    sortingFields: Joi.array()
      .items(
        Joi.object().keys({
          fieldName: Joi.string(),
          label: Joi.string(),
          type: Joi.string()
        })
      )
      .allow(null)
  })
);

export const CHANGE_LABEL_TRANSLATIONS = "CHANGE_LABEL_TRANSLATIONS";

interface ChangeValueAction {
  type: typeof CHANGE_LABEL_TRANSLATIONS;
  name: string;
  value: string | undefined;
}

export const GENERIC_REQUEST_FAILURE = "GENERIC_REQUEST_FAILURE";

interface GenericRequestFailAction {
  type: typeof GENERIC_REQUEST_FAILURE;
  errorMessage: string;
}

export const GENERIC_REQUEST_SUCCESS = "GENERIC_REQUEST_SUCCESS";

interface GenericRequestSuccessAction {
  type: typeof GENERIC_REQUEST_SUCCESS;
}

export const ADD_CHANGE_CONFIG_LABEL_SUCCESS = "ADD_CHANGE_CONFIG_LABEL_SUCCESS";
interface AddChangeTranslationsSuccessAction {
  type: typeof ADD_CHANGE_CONFIG_LABEL_SUCCESS;
}

export const ADD_CHANGE_CONFIG_LABEL_FAILURE = "ADD_CHANGE_CONFIG_LABEL_FAILURE";
interface AddChangeTranslationsFailAction {
  type: typeof ADD_CHANGE_CONFIG_LABEL_FAILURE;
  errorMessage: string;
}

export const DELETE_CONFIG_LABEL_SUCCESS = "DELETE_CONFIG_LABEL_SUCCESS";

interface DeleteConfigLabelSuccessAction {
  type: typeof DELETE_CONFIG_LABEL_SUCCESS;
}

export const GET_CONFIGURABLE_LABELS_SUCCESS = "GET_CONFIGURABLE_LABELS_SUCCESS";

interface GetConfigurableLabelsSuccessAction {
  type: typeof GET_CONFIGURABLE_LABELS_SUCCESS;
  data: ConfigurableLabel[];
}

export const GET_SCREEN_EXTENSIONS_CONFIG_SUCCESS = "GET_SCREEN_EXTENSIONS_CONFIG_SUCCESS";

interface getScreenExtensionsConfigSuccessAction {
  type: typeof GET_SCREEN_EXTENSIONS_CONFIG_SUCCESS;
  data: receivedConfigurableFieldsConfig[];
}

export const ADD_MENU_BUTTON_ON_SCREEN_SUCCESS = "ADD_MENU_BUTTON_ON_SCREEN_SUCCESS";

interface AddMenuButtonOnScreenSuccessAction {
  type: typeof ADD_MENU_BUTTON_ON_SCREEN_SUCCESS;
}

export const ADD_MENU_BUTTON_ON_SCREEN_FAILURE = "ADD_MENU_BUTTON_ON_SCREEN_FAILURE";

interface AddMenuButtonOnScreenFailureAction {
  type: typeof ADD_MENU_BUTTON_ON_SCREEN_FAILURE;
  errorMessage: string;
}

export const GET_SCREEN_EXTENSIONS_SUCCESS = "GET_SCREEN_EXTENSIONS_SUCCESS";

interface GetConfigurableFieldsSuccessAction {
  type: typeof GET_SCREEN_EXTENSIONS_SUCCESS;
  data: ConfigurableField[];
}

export const SELECT_SCREEN_CHANGE = "SELECT_SCREEN_CHANGE";

interface SelectScreenChange {
  type: typeof SELECT_SCREEN_CHANGE;
  name: string;
  value: string | undefined;
}

export const SELECT_BUTTON_SCREEN_CHANGE = "SELECT_BUTTON_SCREEN_CHANGE";

interface SelectButtonScreenChange {
  type: typeof SELECT_BUTTON_SCREEN_CHANGE;
  name: string;
  value: string;
}

export const SET_ACTIVE_SCREEN_BTNS_CONFIG = "SET_ACTIVE_SCREEN_BTNS_CONFIG";

interface SetActiveScreenBtnsConfig {
  type: typeof SET_ACTIVE_SCREEN_BTNS_CONFIG;
  config: configurableButtonsConfig;
}

export const HIDE_INFO_MESSAGE = "HIDE_INFO_MESSAGE";

interface HideInfoMessage {
  type: typeof HIDE_INFO_MESSAGE;
}

export const ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS = "ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS";

interface AddChangeConfigurableSuccessAction {
  type: typeof ADD_CHANGE_CONFIGURABLE_FIELD_SUCCESS;
}

export const SET_CONFIG_FIELD_TO_ADD = "SET_CONFIG_FIELD_TO_ADD";

interface SetConfigurableFieldToAdd {
  type: typeof SET_CONFIG_FIELD_TO_ADD;
  configField: ConfigField;
  screen: string;
  group: string;
}

export const SET_CONFIG_FIELD_TO_EDIT = "SET_CONFIG_FIELD_TO_EDIT";

interface SetConfigurableFieldToEdit {
  type: typeof SET_CONFIG_FIELD_TO_EDIT;
  configField: ConfigField;
  screen: string;
  group: string;
}

export const SET_CONFIG_FIELD_TO_DELETE = "SET_CONFIG_FIELD_TO_DELETE";

interface SetConfigurableFieldToDelete {
  type: typeof SET_CONFIG_FIELD_TO_DELETE;
  field: ActiveConfigField;
}

export const ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE = "ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE";

interface AddChangeConfigurationFailAction {
  type: typeof ADD_CHANGE_CONFIGURABLE_FIELD_FAILURE;
  errorMessage: string;
}

export const ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START = "ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START";

interface AddChangeConfigurableFieldRequestStart {
  type: typeof ADD_CHANGE_CONFIGURABLE_FIELD_REQUEST_START;
}

export const GENERIC_REQUEST_START = "GENERIC_REQUEST_START";

interface GenericRequestStartAction {
  type: typeof GENERIC_REQUEST_START;
}

export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";

interface ChangeActiveTabAction {
  type: typeof CHANGE_ACTIVE_TAB;
  activeTab: string;
}

export const SHOW_ADD_EDIT_CONFIG_LABEL = "SHOW_ADD_EDIT_CONFIG_LABEL";

interface ShowAddEditConfigLabelAction {
  type: typeof SHOW_ADD_EDIT_CONFIG_LABEL;
  show: boolean;
  editMode: boolean;
}

export const SHOW_ADD_EDIT_CONFIGURABLE_FIELD = "SHOW_ADD_EDIT_CONFIGURABLE_FIELD";

interface ShowAddEditConfigurableField {
  type: typeof SHOW_ADD_EDIT_CONFIGURABLE_FIELD;
  show: boolean;
  editConfig: boolean;
}

export const CHANGE_ACTIVE_LABEL = "CHANGE_ACTIVE_LABEL";

interface ChangeActiveLabelAction {
  type: typeof CHANGE_ACTIVE_LABEL;
  label?: ConfigurableLabel;
}

export const CHANGE_CONFIG_FIELD = "CHANGE_CONFIG_FIELD";

interface ChangeConfigFieldAction {
  type: typeof CHANGE_CONFIG_FIELD;
  name: string;
  value: string | undefined;
}

export const TOGGLE_CUSTOM_TREE_VIEW = "TOGGLE_CUSTOM_TREE_VIEW";

interface ToggleCustomTreeView {
  type: typeof TOGGLE_CUSTOM_TREE_VIEW;
  key: string;
  value: boolean;
}

export const CHANGE_ACTIVE_CONFIG_FIELDS = "CHANGE_ACTIVE_CONFIG_FIELDS";

interface ChangeActiveConfigFields {
  type: typeof CHANGE_ACTIVE_CONFIG_FIELDS;
  config: ActiveConfigField;
}

export const SET_ACTIVE_CONFIG_BUTTON = "SET_ACTIVE_CONFIG_BUTTON";

interface SetActiveConfigButton {
  type: typeof SET_ACTIVE_CONFIG_BUTTON;
  config: activeConfigurableButtonConfig;
}

export const SET_CONFIG_BUTTON_TO_DELETE = "SET_CONFIG_BUTTON_TO_DELETE";

interface SetConfigButtonToDelete {
  type: typeof SET_CONFIG_BUTTON_TO_DELETE;
  config: activeConfigurableButtonConfig;
}

export const CLEAR_SCREEN_CONFIG_WORKING_COPY = "CLEAR_SCREEN_CONFIG_WORKING_COPY";

interface ClearScreenConfigWorkingCopy {
  type: typeof CLEAR_SCREEN_CONFIG_WORKING_COPY;
}

export const CLEAR_CONFIG_CREATE_ERROR_MESSAGE = "CLEAR_CONFIG_CREATE_ERROR_MESSAGE";

interface ClearConfigCreateErrorMessage {
  type: typeof CLEAR_CONFIG_CREATE_ERROR_MESSAGE;
}

export const EDIT_SCREEN_CONFIGURATION_START = "EDIT_SCREEN_CONFIGURATION_START";

interface EditScreenConfigurationStart {
  type: typeof EDIT_SCREEN_CONFIGURATION_START;
}

export const EDIT_SCREEN_CONFIGURATION_SUCCESS = "EDIT_SCREEN_CONFIGURATION_SUCCESS";

interface EditScreenConfigurationSuccess {
  type: typeof EDIT_SCREEN_CONFIGURATION_SUCCESS;
}

export const EDIT_SCREEN_CONFIGURATION_FAILURE = "EDIT_SCREEN_CONFIGURATION_FAILURE";

interface EditScreenConfigurationFailure {
  type: typeof EDIT_SCREEN_CONFIGURATION_FAILURE;
  errorMessage: string;
}

export const SET_SCREEN_CONFIG_WORKING_COPY = "SET_SCREEN_CONFIG_WORKING_COPY";

interface SetScreenConfigWorkingCopy {
  type: typeof SET_SCREEN_CONFIG_WORKING_COPY;
  screen: string;
}

export const SCREEN_EDITOR_CONFIG_UPDATED = "SCREEN_EDITOR_CONFIG_UPDATED";

interface ScreenEditorConfigUpdated {
  type: typeof SCREEN_EDITOR_CONFIG_UPDATED;
  updated: boolean;
}

export const DELETE_CONFIGURABLE_FIELD_SUCCESS = "DELETE_CONFIGURABLE_FIELD_SUCCESS";

interface DeleteConfigurableSuccessAction {
  type: typeof DELETE_CONFIGURABLE_FIELD_SUCCESS;
}

export const DELETE_CONFIGURABLE_BUTTON_SUCCESS = "DELETE_CONFIGURABLE_BUTTON_SUCCESS";

interface DeleteConfigurableButtonSuccessAction {
  type: typeof DELETE_CONFIGURABLE_BUTTON_SUCCESS;
}

export type ScreenExtensionsTypes =
  | ChangeValueAction
  | ChangeActiveTabAction
  | ShowAddEditConfigLabelAction
  | ChangeActiveLabelAction
  | AddChangeTranslationsSuccessAction
  | AddChangeTranslationsFailAction
  | GetConfigurableLabelsSuccessAction
  | DeleteConfigLabelSuccessAction
  | GenericRequestFailAction
  | GenericRequestSuccessAction
  | GenericRequestStartAction
  | getScreenExtensionsConfigSuccessAction
  | AddMenuButtonOnScreenSuccessAction
  | AddMenuButtonOnScreenFailureAction
  | SelectScreenChange
  | SelectButtonScreenChange
  | SetActiveScreenBtnsConfig
  | ShowAddEditConfigurableField
  | ChangeConfigFieldAction
  | HideInfoMessage
  | AddChangeConfigurableSuccessAction
  | AddChangeConfigurationFailAction
  | GetConfigurableFieldsSuccessAction
  | ToggleCustomTreeView
  | ChangeActiveConfigFields
  | SetActiveConfigButton
  | SetConfigButtonToDelete
  | ClearScreenConfigWorkingCopy
  | ClearConfigCreateErrorMessage
  | ScreenEditorConfigUpdated
  | DeleteConfigurableSuccessAction
  | DeleteConfigurableButtonSuccessAction
  | AddChangeConfigurableFieldRequestStart
  | SetConfigurableFieldToAdd
  | SetConfigurableFieldToEdit
  | SetConfigurableFieldToDelete
  | EditScreenConfigurationStart
  | EditScreenConfigurationSuccess
  | EditScreenConfigurationFailure
  | SetScreenConfigWorkingCopy;

import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../../breadcrumbs";
import MainContentScroll from "../../mainContentScroll";
import PaginationCA from "../../paginationCA";
import UsersSearch from "./usersSearch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./users.scss";

const RefreshButton = ({props}) => (
  <button type="button" onClick={props.importUsers} className="btn btn-dark btn-sm mr-3 p-0 refresh-button">
    <FontAwesomeIcon
      icon="sync"
      className={"refresh-icon align-baseline " + (props.usersBeingImported ? "fa-spin" : "")}
    />
  </button>
);

class UsersPage extends React.Component {
  componentDidMount() {
    this.props.loadPage(this.props.page, this.props.pageSize);
  }
  render() {
    return (
      <>
        <Breadcrumbs>
          <RefreshButton props={this.props} />
        </Breadcrumbs>
        <UsersSearch
          includeDisabled={this.props.includeDisabled}
          currentQuery={this.props.query}
          onIncludeDisabledChange={this.props.onIncludeDisabledChange}
          onQueryChange={this.props.onQueryChange}
          usersBeingImported={this.props.usersBeingImported}
        />
        {this.props.errorMessage.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {this.props.errorMessage}
          </div>
        )}
        <MainContentScroll>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Contact</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {this.props.users.map(user => (
                <tr key={user.id}>
                  <td>{user.loginId}</td>
                  <td>{user.displayName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </MainContentScroll>
        <PaginationCA
          pageOptions={this.props.pageOptions}
          total={this.props.total}
          currentPage={this.props.page}
          pageSize={this.props.pageSize}
          pageSelect={this.props.loadPage}
          onPageSizeChange={this.props.onPageSizeChange}
          usersBeingImported={this.props.usersBeingImported}
          idPart="users"
        />
      </>
    );
  }
}
const UserShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  loginId: PropTypes.string.isRequired
});
UsersPage.propTypes = {
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(UserShape),
  errorMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default UsersPage;

import {createBrowserHistory} from "history";
import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {reducer as authentication} from "./_reducers/authentication.reducer";
import loading from "./loading/reducers";
import users from "./users/reducers";
import dbReload from "./dbReload/reducers";
import configuration from "./configuration/reducers";
import {reducer as integrations} from "./_reducers/integrations.reducer";
import screenExtensions from "./screenExtensions/reducers";
import sharedStore from "./sharedStore/reducers";
import amr from "./amr/reducers";

export const history = createBrowserHistory();
const rootReducer = combineReducers({
  router: connectRouter(history),
  authentication,
  users,
  loading,
  dbReload,
  configuration,
  integrations,
  screenExtensions,
  sharedStore,
  amr
});
export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>;
